import Head from 'nordic/head';

const Header = () => (
  <Head>
    <link
      href='https://http2.mlstatic.com/storage/a-navigations/3.0.1-yellow-bg/logo_yellow_small.svg'
      rel='icon'
    />
  </Head>
);

export default Header;
