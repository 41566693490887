import type { FC } from 'react';

import { Image } from 'nordic/image';

import { Accordion } from '../Accordion';
import { AccordionItem } from '../AccordionItem';
import useTranslations from './useTranslations';

const NAMESPACE = 'faqs';

const AccordionItemHeader = ({
  title,
  titleClassName,
}: {
  title: string;
  titleClassName: string;
}) => (
  <div className={`${NAMESPACE}__item-header`}>
    <span className={titleClassName}>{title}</span>
  </div>
);

const AccordionContent = ({ text }: { text: string }) => (
  <div className={`${NAMESPACE}__content-item-text`}>{text}</div>
);

const AccordionContentList = ({
  items,
}: {
  items: [{ icon: string; text: string }];
}) => {
  const itemsArrayByElement: Array<JSX.Element> = [];

  items.forEach((item) =>
    itemsArrayByElement.push(
      <div key={item.icon} className={`${NAMESPACE}__content-list-item`}>
        <div className={`${NAMESPACE}__content-list-item-icon`}>
          <Image lazyload='off' src={item.icon} alt='' />
        </div>
        <div className={`${NAMESPACE}__content-list-item-text`}>
          {item.text}
        </div>
      </div>,
    ),
  );

  return (
    <div className={`${NAMESPACE}__content-list`}>{itemsArrayByElement}</div>
  );
};

const Faqs: FC = () => {
  const t = useTranslations();

  return (
    <section className={NAMESPACE}>
      <div className={`${NAMESPACE}__container-title`}>
        <div className={`${NAMESPACE}__divisor`}>
          <Image lazyload='off' src='divisor.svg' alt='divisor' />
        </div>
        <h1 className={`${NAMESPACE}__title`}>{t.title}</h1>
      </div>
      <div className={`${NAMESPACE}__container-accordion`}>
        <Accordion>
          <AccordionItem
            id='what-is-pads'
            header={AccordionItemHeader}
            headerProps={{
              title: t.whatIsPads.title,
            }}
            content={AccordionContent}
            contentProps={{ text: t.whatIsPads.text }}
          />
          <AccordionItem
            id='how-works-pads'
            header={AccordionItemHeader}
            headerProps={{
              title: t.howWorksPads.title,
            }}
            content={AccordionContentList}
            contentProps={{ items: t.howWorksPads.items }}
          />
          <AccordionItem
            id='actual-publications'
            header={AccordionItemHeader}
            headerProps={{
              title: t.actualPublications.title,
            }}
            content={AccordionContent}
            contentProps={{ text: t.actualPublications.text }}
          />
          <AccordionItem
            id='payment'
            header={AccordionItemHeader}
            headerProps={{
              title: t.payment.title,
            }}
            content={AccordionContent}
            contentProps={{ text: t.payment.text }}
          />
          <AccordionItem
            id='products'
            header={AccordionItemHeader}
            headerProps={{
              title: t.products.title,
            }}
            content={AccordionContent}
            contentProps={{ text: t.products.text }}
          />
        </Accordion>
      </div>
    </section>
  );
};

export default Faqs;
